import React from "react";

export const PageNotFound = (props) => {
    const {

    } = props

    return (
        <div className={""}>
            <div>Oops!</div>
            This Page Doesn't Exist!
        </div>
    )
}