import React from "react";
import "./something.scss";

export const Something = (props) => {
    const {

    } = props

    return (
        <div className={"something"}>
            I Am Something
        </div>
    )
}